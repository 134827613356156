import React, { useState } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import BottomCTA from "../components/BottomCta";
import { useTranslation } from "gatsby-plugin-react-i18next";
import WhyPerpetua from "../components/AffiliateProgramPage/WhyPerpetua";
import Icon, { IconType } from "../components/Icon";
import PerpetuaBenchmarker from "../components/AffiliateProgramPage/PerpetuaBenchmarker";
import GetStarted from "../components/AffiliateProgramPage/GetStarted";
import Quote from "../components/AffiliateProgramPage/Quote";
import Faqs from "../components/AffiliateProgramPage/Faqs";
import FormModal from "../components/FormModal";
import { PAGE } from "../constants/pages";

interface AffiliateProgramProps {
  data: any;
}

const AffiliateProgram: React.FC<AffiliateProgramProps> = ({
  data
}) => {

  const pageData = data;
  const { t } = useTranslation();

  const [activeAffiliateModal, setActiveAffiliateModal] =
    useState<boolean>(false);

  const heroCta = {
    text: t("Apply Now"),
    route: t("/demo/")
  };

  const whyPerpetuaFeatures = [
    {
      title: t("Unlimited earnings"),
      text: t(
        "$2000 every week? Your success is our success, so there are no limits!"
      ),
      svg: <Icon className="" iconType={IconType.sales} />
    },
    {
      title: t("Commission for every report"),
      text: t(
        "Yes, that’s right, a commission for a free report. That’s unheard off!"
      ),
      svg: <Icon className="" iconType={IconType.phone} />
    },
    {
      title: t("Special deals"),
      text: t("Get personalized codes and deals for your audience."),
      svg: <Icon className="" iconType={IconType.pie} />
    },
    {
      title: t("Get paid immediately"),
      text: t(
        "Your payout will be processed immediately after it is reviewed – in $ to your PayPal account."
      ),
      svg: <Icon className="" iconType={IconType.pie} />
    }
  ];

  const perpetuaBenchmarker = {
    title: t("The Perpetua Benchmarker"),
    description: [
      t(
        "Benchmarker is the first and only report that will tell your audience how they can master Amazon PPC."
      ),
      t(
        "As an affiliate you will earn a commission for every report that will be generated. The crazy thing? The report is not only incredibly insightful – it’s also free!"
      ),
      t(
        "Simply add an affiliate link or banner to your website, blog, video review or email newsletter — it’s that easy."
      )
    ],
    cta: {
      text: t("Learn more about the Benchmarker"),
      route: t("/amazon-advertising-benchmark-tool/")
    }
  };

  const getStartedSteps = [
    t("Register"),
    t("Application Review"),
    t("Qualification Call"),
    t("Perpetua Affiliate!")
  ];

  const quote = {
    text: t(
      "Your success is our success! We will do whatever it takes to make you successful."
    ),
    author: {
      name: "Thomas Ropel, VP Growth"
    }
  };

  const faqs = [
    {
      title: t("What are the eligibility requirements?"),
      content: t(
        "Manage a relevant platform (i.e. blog/website) around the Amazon industry. Amazon PPC focus is a plus!"
      )
    },
    {
      title: t("How many followers do I need?"),
      content: t(
        "It depends on your target audience, but it will be around ~5,000 subscribers / followers."
      )
    },
    {
      title: t("Why Perpetua?"),
      content: t(
        "We offer top-of-the-line Amazon software with a range of features covering Amazon SEO and PPC optimization, 3 kinds of advertising automation, plus much more. Thousands of brands and agencies worldwide grow their Amazon business together with Perpetua."
      )
    },
    {
      title: t("Will I get support?"),
      content: t(
        "Yes, after joining our program as an affiliate, we’ll make sure you get full support when it’s needed and provide you with all the details that you need to help us promote our service."
      )
    }
  ];

  return (
    <Layout>
      <SEO
        title={t("Amazon Affiliate Program | Perpetua")}
        description={t(
          "Partner up with Perpetua and get paid for giving your audience the tools and insights they need to succeed on Amazon."
        )}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />
      <HeroSection
        page={PAGE.AFFILIATE}
        bgGradient="bg-white"
        gradientTitle={[
          t("Join the fastest growing affiliate program in the industry")
        ]}
        gradientPosition={2}
        h1Title={t("Perpetua Affiliate Program")}
        h1BadgeShadow={false}
        cta={heroCta}
        description={t(
          "Partner up with Perpetua and get paid for giving your audience the tools and insights they need to succeed on Amazon."
        )}
        affiliateModal={setActiveAffiliateModal}
        imageReplacement={
        <StaticImage
          src="../images/affiliate-program/affiliate-hero.png"
          alt=""
          className="w-full md:h-full hidden md:block"
        />
        }
      />
      <WhyPerpetua
        gradientTitle={[t("Why become a"), t("Perpetua affiliate?")]}
        gradientPosition={2}
        description={t(
          "Perpetua is a leading Amazon All-In-One software solution that helps sellers and brands on Amazon to grow their business – on Amazon PPC and beyond."
        )}
        features={whyPerpetuaFeatures}
      />

      <PerpetuaBenchmarker
        title={perpetuaBenchmarker.title}
        description={perpetuaBenchmarker.description}
        cta={perpetuaBenchmarker.cta}
      />

      <GetStarted
        gradientTitle={[t("Get started in"), t("just 4 steps")]}
        gradientPosition={2}
        steps={getStartedSteps}
      />

      <Quote quote={quote} />

      <Faqs faqs={faqs} />

      <BottomCTA
        primaryButtonId={""}
        secondaryButtonId={""}
        title={t("Become a Perpetua affiliate")}
        subtitle={t(
          "Get paid for every download. Simple. Register to become a Perpetua affiliate now!"
        )}
        primaryButtonText={t("Apply Now")}
        primaryButtonOnClick={() => {
          setActiveAffiliateModal(true);
        }}
      />

      {activeAffiliateModal && (
        <FormModal
          setActiveAffiliateModal={setActiveAffiliateModal}
          title={"Apply Now"}
          formType={"affiliate"}
          data={activeAffiliateModal}
        />
      )}
    </Layout>
  );
};

export default AffiliateProgram;

export const data = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(
      relativePath: { eq: "affiliate-program/affiliate-ogImage.png" }
    ) {
      publicURL
    }
  }
`;
